import React, { useState, useContext } from 'react';
import UrlNodeServer from '../../../../../api/NodeServer';
import { Card, CardBody, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row, Spinner } from 'reactstrap';
import './styles.css';
import InvoiceHeader from './header';
import ProductFinder from './productFinder';
import ProdListSell from './list/prodListSell';
import productsSellContext from '../../../../../context/productsSell';
import formatMoney from 'Function/NumberFormat';
import swal from 'sweetalert';
import moment from 'moment';
import axios from 'axios';
import FileSaver from 'file-saver';
import { verificadorCuit } from 'Function/VerificadorCuit';
import ModalChange from './modalChange';
import FormasPagoMod from './formasPago';

const Ventas = ({ setValidPV }) => {
  const [clienteBool, setClienteBool] = useState(0);
  const [factFiscBool, setFactFiscBool] = useState(0);
  const [tipoDoc, setTipoDoc] = useState(80);
  const [ptoVta, setPtoVta] = useState({ id: 0 });
  const [envioEmailBool, setEnvioEmailBool] = useState(0);
  const [emailCliente, setEmailCliente] = useState('');
  const [ndoc, setNdoc] = useState('');
  const [razSoc, setRazSoc] = useState('');
  const [formaPago, setFormaPago] = useState(0);
  const [invalidNdoc, setInvalidNdoc] = useState(false);
  const [tfact, setTfact] = useState(1);
  const [condIvaCli, setCondIvaCli] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [descuentoPerc, setDescuentoPer] = useState(0);
  const [variosPagos, setVariosPagos] = useState([]);
  const [total, setTotal] = useState(0);
  const [modal1, setModal1] = useState(false);
  const [fechaVto, setFechaVto] = useState(moment(new Date().setDate(new Date().getDate() + 1)).format('YYYY-MM-DD'));

  const { totalPrecio, cancelarCompra, productsSellList } = useContext(productsSellContext);

  const cancelar = () => {
    swal({
      title: '¿Está seguro de canelar la compra?',
      text: 'Esta desición eliminará todos los productos cargados en el carrito de compras.',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancelar', 'Vacíar Carrito'],
    }).then((willDelete) => {
      if (willDelete) {
        setClienteBool(0);
        setEnvioEmailBool(0);
        cancelarCompra();
      }
    });
  };

  const generarFactura = async () => {
    let data;
    if (parseInt(clienteBool) === 0) {
      data = {
        dataFact: {
          fecha: moment(new Date()).format('YYYY-MM-DD'),
          pv_id: ptoVta.id,
          fiscal: factFiscBool,
          forma_pago: formaPago,
          cond_iva: condIvaCli,
          enviar_email: envioEmailBool,
          cliente_email: emailCliente,
          lista_prod: productsSellList,
          descuentoPerc: descuentoPerc,
          variosPagos: variosPagos,
        },
        fiscal: factFiscBool,
        fechaVto: fechaVto,
      };
    } else {
      data = {
        dataFact: {
          fecha: moment(new Date()).format('YYYY-MM-DD'),
          pv_id: ptoVta.id,
          fiscal: factFiscBool,
          forma_pago: formaPago,
          cond_iva: condIvaCli,
          enviar_email: envioEmailBool,
          cliente_email: emailCliente,
          cliente_bool: parseInt(clienteBool),
          cliente_tdoc: tipoDoc,
          cliente_ndoc: ndoc,
          cliente_name: razSoc,
          lista_prod: productsSellList,
          descuentoPerc: descuentoPerc,
          variosPagos: variosPagos,
        },
        fiscal: factFiscBool,
        fechaVto: fechaVto,
      };
    }

    if (productsSellList.length > 0) {
      if (parseInt(clienteBool) === 1) {
        if (parseInt(tipoDoc) === 96) {
          const largo = ndoc.length;
          if (largo > 8 || largo < 7) {
            swal('Error en el DNI!', 'El DNI que trata de cargar es inválido! Reviselo.', 'error');
          } else {
            generarFacturaFinal(data);
          }
        } else {
          const esCuit = verificadorCuit(ndoc).isCuit;
          if (esCuit) {
            generarFacturaFinal(data);
          } else {
            swal('Error en el CUIT!', 'El CUIT que trata de cargar es inválido! Reviselo.', 'error');
          }
        }
      } else {
        generarFacturaFinal(data);
      }
    } else {
      swal('Error en el carrito!', 'No hay productos para Presupuestar! Controlelo.', 'error');
    }
  };

  const generarFacturaFinal = async (data) => {
    setProcessing(true);
    await axios
      .post(UrlNodeServer.invoicesDir.sub.presupuesto, data, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
          Accept: 'application/pdf',
        },
      })
      .then((res) => {
        let headerLine = res.headers['content-disposition'];
        const largo = parseInt(headerLine.length);
        let filename = headerLine.substring(21, largo);
        var blob = new Blob([res.data], { type: 'application/pdf' });
        FileSaver.saveAs(blob, filename);
        cancelarCompra();
        setDescuentoPer(0);
        setFormaPago(0);
        setFactFiscBool(0);
        setNdoc('');
        setClienteBool(0);
        setEnvioEmailBool(0);
        setVariosPagos([]);
        setRazSoc('');
        swal('Nuevo Presupuesto!', 'El Presupuesto se ha generado con éxito!', 'success');
      })
      .catch(async (err) => {
        if (err.code === 'ECONNABORTED') {
          await swal(
            'Tiempo de espera superado!',
            'Ha tardado demasiado el servidor en responder. En breve se generará El Presupuesto y la podrá ver reflejada consultando en el sistema.',
            'error',
          );
          await swal('Le mandaremos un email en cuanto se genere El Presupuesto.', '', 'info');
        } else {
          console.log('err :>> ', err);
          swal(
            'Error inesperado!',
            'El Presupuesto no se pudo generar por un error en los datos! Controle que no falten datos importantes en la cabecera',
            'error',
          );
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Card>
      <ModalChange descuentoPerc={descuentoPerc} modal={modal1} toggle={() => setModal1(!modal1)} />
      <CardBody>
        {processing ? (
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ color: 'green' }}>Procesando Presupuesto...</h2>
            <Spinner type="grow" color="light" style={{ width: '250px', height: '250px' }} />{' '}
          </div>
        ) : (
          <>
            <InvoiceHeader
              setPtoVta={setPtoVta}
              setFactFiscBool={setFactFiscBool}
              setClienteBool={setClienteBool}
              setTipoDoc={setTipoDoc}
              setNdoc={setNdoc}
              setRazSoc={setRazSoc}
              setEmailCliente={setEmailCliente}
              setEnvioEmailBool={setEnvioEmailBool}
              setFormaPago={setFormaPago}
              factFiscBool={factFiscBool}
              clienteBool={clienteBool}
              tipoDoc={tipoDoc}
              ndoc={ndoc}
              razSoc={razSoc}
              formaPago={formaPago}
              envioEmailBool={envioEmailBool}
              emailCliente={emailCliente}
              ptoVta={ptoVta}
              invalidNdoc={invalidNdoc}
              setInvalidNdoc={setInvalidNdoc}
              tfact={tfact}
              setTfact={setTfact}
              setCondIvaCli={setCondIvaCli}
              setValidPV={setValidPV}
              setModal1={setModal1}
              modal1={modal1}
              setFechaVto={setFechaVto}
              fechaVto={fechaVto}
            />

            <br />

            <ProductFinder />

            <ProdListSell />
            <Row>
              <Col md="6">
                <FormasPagoMod
                  clienteBool={clienteBool}
                  formaPago={formaPago}
                  variosPagos={variosPagos}
                  setVariosPagos={setVariosPagos}
                  factFiscBool={factFiscBool}
                  total={total}
                  setTotal={setTotal}
                />
              </Col>
              <Col md="6">
                <Row style={{ marginTop: 0 }}>
                  <Col md="4" style={{ marginLeft: 'auto', textAlign: 'right' }}>
                    <Label style={{ fontSize: '25px', fontWeight: 'bold' }}>Subtotal:</Label>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Input
                        style={{
                          fontSize: '20px',
                          fontWeight: 'bold',
                          textAlign: 'right',
                        }}
                        type="text"
                        value={'$ ' + formatMoney(totalPrecio)}
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ marginTop: 0 }}>
                  <Col md="4" style={{ marginLeft: 'auto', textAlign: 'right' }}>
                    <Label style={{ fontSize: '25px', fontWeight: 'bold' }}>Descuento:</Label>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Row>
                        <Col md="4">
                          <InputGroup>
                            <Input
                              style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                textAlign: 'right',
                              }}
                              type="text"
                              value={descuentoPerc}
                              onChange={(e) => setDescuentoPer(e.target.value)}
                              min={0}
                              max={100}
                            />
                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                          </InputGroup>
                        </Col>
                        <Col md="8">
                          <Input
                            style={{
                              fontSize: '20px',
                              fontWeight: 'bold',
                              textAlign: 'right',
                            }}
                            type="text"
                            value={
                              '$ ' +
                              formatMoney(
                                descuentoPerc > 0 && descuentoPerc <= 100 ? totalPrecio * (descuentoPerc / 100) : 0,
                              )
                            }
                            disabled
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ marginTop: 0 }}>
                  <Col md="4" style={{ marginLeft: 'auto', textAlign: 'right' }}>
                    <Label style={{ fontSize: '25px', fontWeight: 'bold' }}>Total:</Label>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <Input
                        style={{
                          fontSize: '20px',
                          fontWeight: 'bold',
                          textAlign: 'right',
                        }}
                        type="text"
                        value={
                          '$ ' +
                          formatMoney(
                            parseFloat(descuentoPerc) > 0 && parseFloat(descuentoPerc) <= 100
                              ? totalPrecio - totalPrecio * (descuentoPerc / 100)
                              : totalPrecio,
                          )
                        }
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: 0, textAlign: 'center' }}>
              <Col>
                <button
                  className="btn btn-primary"
                  style={{ margin: '15px', width: '220px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    generarFactura();
                  }}
                >
                  Confirmar Presupuesto
                </button>
                <button
                  className="btn btn-danger"
                  style={{ margin: '15px', width: '200px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    cancelar();
                  }}
                >
                  Cancelar
                </button>
              </Col>
            </Row>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default Ventas;
