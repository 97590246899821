import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import ModalSearchCuit from './modalSearchCuit';
import NdocInput from './ndocInput';
import PtosVtas from './ptosVta';
import Form from 'reactstrap/lib/Form';
const InvoiceHeader = ({
  setPtoVta,
  factFiscBool,
  clienteBool,
  setClienteBool,
  tipoDoc,
  ndoc,
  setTipoDoc,
  setNdoc,
  setRazSoc,
  setEmailCliente,
  setEnvioEmailBool,
  razSoc,
  ptoVta,
  invalidNdoc,
  setInvalidNdoc,
  setTfact,
  setCondIvaCli,
  setValidPV,
  setFechaVto,
  fechaVto,
}) => {
  const [ptoVtaList, setPtoVtaList] = useState(<option>No hay puntos de venta relacionados</option>);
  const [cuitSearchModal, setCuitSearchModal] = useState(false);

  const cuitSearchToggle = () => {
    setCuitSearchModal(!cuitSearchModal);
  };

  return (
    <>
      <ModalSearchCuit
        cuitSearchToggle={cuitSearchToggle}
        cuitSearchModal={cuitSearchModal}
        setTipoDoc={setTipoDoc}
        setEmailCliente={setEmailCliente}
        setNdoc={setNdoc}
        setRazSoc={setRazSoc}
        setEnvioEmailBool={setEnvioEmailBool}
        setCondIvaCli={setCondIvaCli}
      />
      <Form>
        <Row>
          <Col style={{ border: '2px solid red', padding: '15px', margin: 0 }}>
            <Row>
              <Col md="12">
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label for="exampleEmail">Fecha</Label>
                      <Input type="date" value={moment(new Date()).format('YYYY-MM-DD')} disabled />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for="exampleEmail">Fecha Vto.</Label>
                      <Input type="date" value={fechaVto} min={moment(new Date()).format('YYYY-MM-DD')} />
                    </FormGroup>
                  </Col>
                  <PtosVtas
                    setPtoVta={setPtoVta}
                    setPtoVtaList={setPtoVtaList}
                    ptoVtaList={ptoVtaList}
                    ptoVta={ptoVta}
                    colSize={6}
                    setValidPV={setValidPV}
                  />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label for="tipoClienteTxt">Cliente</Label>
                  <Input
                    onChange={(e) => setClienteBool(e.target.value)}
                    value={clienteBool}
                    type="select"
                    id="tipoClienteTxt"
                  >
                    <option value={0}>Consumidor Final</option>
                    <option value={1}>Cliente Identificado</option>
                  </Input>
                </FormGroup>
              </Col>
              {parseInt(clienteBool) === 1 ? (
                <>
                  <Col md="2">
                    <FormGroup>
                      <Label for="factFiscTxt">Tipo Doc.</Label>
                      <Input
                        type="select"
                        id="factFiscTxt"
                        value={tipoDoc}
                        onChange={(e) => setTipoDoc(e.target.value)}
                      >
                        <option value={80}>CUIT</option>
                        <option value={96}>DNI</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <NdocInput
                    tipoDoc={tipoDoc}
                    setTipoDoc={setTipoDoc}
                    ndoc={ndoc}
                    setNdoc={setNdoc}
                    setRazSoc={setRazSoc}
                    setEmailCliente={setEmailCliente}
                    setEnvioEmailBool={setEnvioEmailBool}
                    invalidNdoc={invalidNdoc}
                    setInvalidNdoc={setInvalidNdoc}
                    ptoVta={ptoVta}
                    setTfact={setTfact}
                    setCondIvaCli={setCondIvaCli}
                    factFiscBool={factFiscBool}
                    colSize={3}
                  />
                  <Col md="4">
                    <Label for="razSocTxt">{parseInt(tipoDoc) === 80 ? 'Raz. Soc.' : 'Nombre'}</Label>
                    <InputGroup>
                      <Input
                        type="text"
                        id="razSocTxt"
                        value={razSoc}
                        onChange={(e) => setRazSoc(e.target.value)}
                        required
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          className="btn btn-info"
                          onClick={(e) => {
                            e.preventDefault();
                            setCuitSearchModal(true);
                          }}
                        >
                          <i className="fas fa-search"></i>
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default InvoiceHeader;
